// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let _this = this;

    var doc = document.documentElement;
    var w = window;
  
    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;
  
    var header = document.getElementById('header');
  
    var checkScroll = function() {
      
      /*
      ** Find the direction of scroll
      ** 0 - initial, 1 - up, 2 - down
      */
     
     curScroll = w.scrollY || doc.scrollTop;
     if (curScroll > prevScroll) { 
       //scrolled up
       direction = 2;
      }
      else if (curScroll < prevScroll) { 
        //scrolled down
        direction = 1;
      }
      
      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }
  
      prevScroll = curScroll;
    };
  
    var toggleHeader = function(direction, curScroll) {
      if (direction === 2 && curScroll > 52) { 
  
        //replace 52 with the height of your header in px
  
        if(header) {
          header.classList.add('hidden');
        }
        prevDirection = direction;
      }
      else if (direction === 1) {
        if(header) {
          header.classList.remove('hidden');
        }
        prevDirection = direction;
      }
    };
  
    window.addEventListener('scroll', checkScroll);
  
    _this.initLottie();

    addEventListener('hashchange', event => {
      setTimeout(function() {
        window.scrollBy(0, -1);
        _this.initLottie();
      },100);
    });
  }

  toggleMenu() {
    document.querySelector('.c-mobile-menu').classList.toggle('hidden');
  }

  initLottie() {
    let lottie = this.element.getAttribute('data-lottie');
    console.log(lottie);
    if(window.animation) {
      window.animation.destroy();
    }
    window.animation = bodymovin.loadAnimation({
      container: document.getElementById('meet-logo-destop'), // Required
      path: `/lotties/${lottie}.json`, // Required
      renderer: 'canvas', // Required
      loop: true, // Optional
      autoplay: true, // Optional
      name: "Hello World", // Name for future reference. Optional.
    }) 
  }

  toggleBanner() {
    document.querySelector('#header-banner').remove();
    if(document.querySelector('#header-fixed')) {
      document.querySelector('#header-fixed').classList.remove('c-header--with-banner');
    }
    if(document.querySelector('#header')) {
      document.querySelector('#header').classList.remove('c-header--with-banner');
    }
  }
}
