// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  privateRegistration() {
    let element = document.getElementById("registration");
    document.getElementById("egyeni_csomag").checked = true;
    document.querySelectorAll('.is-corporate').forEach((item)=> {
      item.classList.add('hidden');
    })

    document.querySelectorAll('.is-personal').forEach((item)=> {
      item.classList.remove('hidden');
    })
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  companyRegistration() {
    let element = document.getElementById("registration");
    document.getElementById("ceges_csomag").checked = true;
    document.querySelectorAll('.is-corporate').forEach((item)=> {
      item.classList.remove('hidden');
    })

    document.querySelectorAll('.is-personal').forEach((item)=> {
      item.classList.add('hidden');
    })
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  
}