// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
  
    _this.toggleMenuItems();
    
    document.querySelectorAll('.lsd-radio').forEach((item) => {
      item.addEventListener('change', ()=> {
        _this.toggleMenuItems();
      });
    });
  }

  question() {
    document.getElementById('lead_source').value = 'lsd lenne még kérdésem';
    document.getElementById('lsd_apply').click();
  }

  questionZip() {
    document.getElementById('lead_source').value = 'zip lenne még kérdésem';
    document.getElementById('lsd_apply').click();
  }

  toggleMenuItems() {
    let egyeni_csomag = document.getElementById('egyeni_csomag');
    let ceges_csomag = document.getElementById('ceges_csomag');

    if(egyeni_csomag.checked) {
      document.querySelectorAll('.is-corporate').forEach((item,index)=> {
        item.classList.add('hidden');


        item.querySelectorAll('input[type="text"]').forEach((input)=> {
          if(input.getAttribute('data-required') == 'true') {
            input.removeAttribute('required');
          }
        })
      })

      document.querySelectorAll('.is-personal').forEach((item)=> {
        item.classList.remove('hidden');


        item.querySelectorAll('.c-education-radio-item__checkbox').forEach((input,index)=> {
          if(index == 0) {
            input.checked = true;
          }
          if(input.getAttribute('data-checked') == 'true') {
            input.checked = true;
          }
        })
      })
    }

    if(ceges_csomag.checked) {
      document.querySelectorAll('.is-corporate').forEach((item)=> {
        item.classList.remove('hidden');

        item.querySelectorAll('input[type="text"]').forEach((input)=> {
          if(input.getAttribute('data-required') == 'true') {
            input.setAttribute('required', 'required');
          }
        })

        item.querySelectorAll('.c-education-radio-item__checkbox').forEach((input,index)=> {
          if(index == 0) {
            input.checked = true;
          }
          if(input.getAttribute('data-checked') == 'true') {
            input.checked = true;
          }
        })
      })

      document.querySelectorAll('.is-personal').forEach((item)=> {
        item.classList.add('hidden');
      })
    }
  }
}
