// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.location = this.element.getAttribute('data-location');
    this.locationMobile = this.element.getAttribute('data-location-mobile');
  }

  toggleDetails() {
    this.element.querySelector('.c-accordion').classList.toggle('transform');
    this.element.querySelector('.c-accordion').classList.toggle('rotate-180');
    if(screen.width > 639) {
      if(window.lastItem === this.element.getAttribute('data-title') && document.getElementById(this.location).classList.contains('hidden') == false) {
        document.getElementById(this.location).classList.add('hidden');

        document.querySelectorAll('.c-accordion').forEach((item)=> {
          item.classList.remove('transform');
          item.classList.remove('rotate-180');
        });
      } else {
        if (window.lastItem != this.element.getAttribute('data-title')) {
          document.getElementById(this.location).classList.remove('hidden');
        } else {
          document.getElementById(this.location).classList.toggle('hidden');
        }
      }
      document.getElementById(this.location).querySelector('.c-education-day-details__title').innerHTML = this.element.getAttribute('data-title');
      document.getElementById(this.location).querySelector('.c-education-day-details__paragraph1').innerHTML = this.element.getAttribute('data-paragraph1');
      document.getElementById(this.location).querySelector('.c-education-day-details__paragraph2').innerHTML = this.element.getAttribute('data-paragraph2');
      document.getElementById(this.location).querySelector('.c-education-day-details__tags').innerHTML = this.element.getAttribute('data-tags');
      window.lastItem = this.element.getAttribute('data-title');
      window.lastLocation = this.location;
    }
    else {
      document.querySelectorAll('.c-education-day-details').forEach((item)=> {
        item.classList.add('hidden');
      });

      document.getElementById(this.locationMobile).classList.toggle('hidden');
      if(window.lastItem === this.element.getAttribute('data-title')) {
        document.getElementById(this.locationMobile).classList.add('hidden');
      }
      document.getElementById(this.locationMobile).querySelector('.c-education-day-details__title').innerHTML = this.element.getAttribute('data-title');
      document.getElementById(this.locationMobile).querySelector('.c-education-day-details__paragraph1').innerHTML = this.element.getAttribute('data-paragraph1');
      document.getElementById(this.locationMobile).querySelector('.c-education-day-details__paragraph2').innerHTML = this.element.getAttribute('data-paragraph2');
  
      document.getElementById(this.locationMobile).querySelector('.c-education-day-details__tags').innerHTML = this.element.getAttribute('data-tags');
  
      console.log(document.getElementById(this.locationMobile).classList);
      window.lastItem = this.element.getAttribute('data-title');
    }
  }
  
  close() {
    document.querySelectorAll('.c-education-day-details').forEach((item)=> {
      item.classList.add('hidden');
    });
  }
}
