// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/app.scss';
require.context('../images', true)
import Swiper, { Navigation, Pagination } from 'swiper';
import LazyLoad from "vanilla-lazyload";
import bodymovin from 'lottie-web/build/player/lottie';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css';

window.Swiper = Swiper;

const lazyBackground = new LazyLoad({
  // DON'T PASS use_native: true HERE
});
window.lazyBackground = lazyBackground
window.bodymovin = bodymovin;

Rails.start()
ActiveStorage.start()

import "controllers"


document.addEventListener("DOMContentLoaded", function(event) {
  lazyBackground.update();
});

document.addEventListener('turbo:render', function () {
  lazyBackground.update();
});

document.addEventListener('turbo:before-visit', function () {
  if(window.animation) {
    window.animation.destroy();
  }
});