// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let hasValue = false;
      document.getElementById('lead_name').addEventListener('input',function() {
        if(document.getElementById('lead_name').value){
          document.getElementById('send-message').disabled = false;
          document.getElementById('send-message').classList.remove('bg-athensGray');
          document.getElementById('send-message').classList.add('bg-purpleHeart');
          document.getElementById('send-message').classList.add('text-white');
        } else {
          document.getElementById('send-message').disabled = true;
          document.getElementById('send-message').classList.add('bg-athensGray');
          document.getElementById('send-message').classList.add('text-manatee');
          document.getElementById('send-message').classList.remove('bg-purpleHeart');
          document.getElementById('send-message').classList.remove('text-white');
        }

      });


  }
}
