// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let prog = document.getElementById('progress');

    let body = document.body,
        html = document.documentElement;

    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    const setProgress = () => {
      let scrollFromTop = (document.documentElement.scrollTop || body.scrollTop) + html.clientHeight;
      let width = scrollFromTop / height * 100 + '%';

      console.log('scroll', html.clientHeight, body.scrollTop);

      prog.style.width = width;
    }

    window.addEventListener('scroll', setProgress);

    setProgress();


  }
}
