// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  toggleFields() {
    document.getElementById('service-selection').classList.add('hidden');
    document.getElementById('fields-selection').classList.toggle('hidden');

    this.element.querySelector('.c-accordion--fields').classList.toggle('transform');
    this.element.querySelector('.c-accordion--fields').classList.toggle('rotate-180');
  }

  toggleServices() {
    document.getElementById('fields-selection').classList.add('hidden');
    document.getElementById('service-selection').classList.toggle('hidden');

    this.element.querySelector('.c-accordion--services').classList.toggle('transform');
    this.element.querySelector('.c-accordion--services').classList.toggle('rotate-180');
  }

  hideSelection() {
    document.getElementById('service-selection').classList.add('hidden');
    document.getElementById('fields-selection').classList.add('hidden');

    this.element.querySelector('.c-accordion--fields').classList.remove('transform');
    this.element.querySelector('.c-accordion--fields').classList.remove('rotate-180');
    this.element.querySelector('.c-accordion--services').classList.remove('transform');
    this.element.querySelector('.c-accordion--services').classList.remove('rotate-180');
  }
}
