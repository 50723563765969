// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const swiper = new Swiper('.c-main-logos', {
      pagination: {
        el: ".swiper-pagination",
      },
      // Default parameters
      slidesPerView: 5,
      spaceBetween: 40,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 640px
        970: {
          slidesPerView: 7,
          spaceBetween: 20
        }
      },
    })
  }
}